module.exports = {
  props: {
    color: {
      type: String,
      default: "#787486",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};